<template>
  <div class="text-center">
    <v-dialog v-model="modal.display" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ modal.title }}
        </v-card-title>

        <v-card-text class="pt-5">
          <p v-if="modal.message">
            {{ modal.message }}
          </p>
          <p v-else >
            <span v-for="word in modal.solution" :key="word" :class="( sesh.session.correct.includes(word) ? 'font-weight-black' : '')">
              {{ word }}
            </span>
          </p>
        </v-card-text>


        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="modal.finished">
            <v-btn @click="resetGame()" color="primary">
              Reset Game
            </v-btn>
            <v-btn @click="gameList()" color="success">
              Return to game list
            </v-btn>
          </div>
          <div v-else>
          <v-btn @click="closeModal()" color="success">
            OK
          </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mapState
  } from 'vuex';

  export default {
    computed: {
      ...mapState(['modal']),
      ...mapState(['sesh']),
    },
    methods: {
      closeModal() {
        this.$store.dispatch('modal/clearModal');
      },
    },
  };
</script>