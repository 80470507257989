<template>
  <div>
    <v-col>
      <!-- Keyboard Input -->
      <template v-for="(letter, i) in jumble.letters">
        <br v-if="(i % 3 == 0) && (i !== 0)" v-bind:key="i">
        <button class="jumble button" outlined v-bind:id="letter.letter + i" v-bind:key="letter.letter + i"
          v-on:click="letterPress($event.target)"
          v-bind:class="{ required: letter.required, pressed: pressed(letter) }">{{ letter.letter }}</button>
      </template>
    </v-col>
    <v-col>
      <!-- Check button -->
      <v-btn id="butCheck" class="mx-2" fab dark small v-bind="attrs" v-on="on" color="info"
        @click="$emit('requestChecking')">
        <v-icon dark>fa-check</v-icon>
      </v-btn>
    </v-col>
  </div>
</template>

<script>
  import {
    mapState,
  } from 'vuex';

  export default {
    computed: {
      ...mapState(['sesh']),
      ...mapState(['jumble']),
    },
    methods: {
      pressed(letter) {
        if (this.sesh.session.guess) {
          return this.sesh.session.guess.includes(letter.letter)
        } else {
          return false
        }
      },
      letterPress(event) {
        var letter = event.innerText
        var guess = this.sesh.session.guess + letter
        this.$store.dispatch('sesh/updateGuess', guess);
      },
    }
  };
</script>