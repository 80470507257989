<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip v-bind="attrs" v-on="on" class="mx-1" v-if="sesh.session" dark>
          <v-icon @click="toggle()" small dark>{{ ( sesh.isTimerRunning ? 'fa-pause' : 'fa-play' ) }}</v-icon>
          &nbsp;
          {{ sesh.session.timer }}
        </v-chip>
      </template>
      <span>Game timer</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip v-bind="attrs" v-on="on" class="mx-1" v-if="sesh.session" dark>
          CGPM: {{ sesh.session.cgpm }}
        </v-chip>
      </template>
      <span>Correct guesses per minute</span>
    </v-tooltip>
  </div>
</template>

<script>
  import {
    mapState
  } from 'vuex';

  export default {
    methods: {
      toggle() {
        this.$store.dispatch('sesh/toggleTimer')
      }
    },
    computed: {
      ...mapState(['jumble', 'sesh']),
    },
    mounted() {
      setInterval(() => {
        this.$store.dispatch('sesh/updateTimer')
      }, 100)
    }
  };
</script>