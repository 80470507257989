<template>
  <v-row id="game">
    <loader v-if="!sesh.session"></loader>
    <jumble v-else></jumble>
  </v-row>
</template>

<script>
import {
  mapState,
} from 'vuex';
import Loader from '../components/Loader.vue';
import Jumble from '../components/Jumble.vue';

export default {
  components: {
    Loader,
    Jumble,
  },
  computed: {
    ...mapState(['sesh']),
  },
};
</script>
