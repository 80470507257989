<template>
  <div>
    <v-row>
      <v-col>
        <v-list dense rounded>
          <v-subheader>Correct</v-subheader>
          <v-chip-group column>
            <v-chip class="green--text text--darken-2" v-for="answer in correctAlphabetised" v-bind:key="answer" @click="define(answer)">
              {{ answer }}
            </v-chip>
          </v-chip-group>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list dense>
          <v-subheader>Incorrect</v-subheader>
          <v-chip-group column>
            <v-chip class="orange--text text--darken-3" v-for="answer in sesh.session.incorrect" v-bind:key="answer">
              {{ answer }}
            </v-chip>
          </v-chip-group>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {
    mapState,
  } from 'vuex';

  export default {
    computed: {
      ...mapState(['jumble']),
      ...mapState(['sesh']),
      correctAlphabetised() {
        return this.$_.uniq(this.$_.sortBy(this.sesh.session.correct))
      }
    },
    methods: {
      define(data) {
        this.$store.dispatch('sesh/showDefinition', data)
      }
    }
  }
</script>