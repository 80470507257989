var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[(_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name !== 'ListGame';
}))?_c('v-main',[_c('sidebar'),_c('v-main',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('router-view')],1)],1),_c('v-footer',{staticStyle:{"background-color":"rgb(215, 215, 215)","padding-top":"13px","padding-bottom":"11px"},attrs:{"color":("" + (_vm.jumble.color)),"app":""}},[_c('div',{staticStyle:{"color":"#2b2c2d","font-size":"14px","line-height":"15px","margin-bottom":"0","font-weight":"500"}},[_vm._v(" Provided by Auspac Media ")])])],1):_vm._e(),(_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'ListGame';
}))?_c('v-main',[_c('router-view')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }